<template>
  <v-col :cols="12">
    <label>{{ label }}</label>
    <ckeditor v-model="ckvalue" :config="editorConfig"></ckeditor>
  </v-col>
</template>

<script>
import CKEditor from "ckeditor4-vue";

export default {
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    value: {
      required: true,
    },
    label: {
      required: true,
      type: String,
    },
    editorConfig: {
      required: false,
      type: Object,
      default: () => ({
        language: "fa",
      }),
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ckvalue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style></style>
